@import '../theme-bootstrap';

.product-palette {
  position: relative;
  &::before {
    content: '';
    width: 14px;
    height: 14px;
    background: url('#{$base-theme-path}img/icons/src/quickshop_arrow.svg') no-repeat;
    background-size: cover;
    display: block;
  }
  @include breakpoint($small-down) {
    overflow: hidden;
    &::before {
      position: absolute;
      z-index: 2;
      color: $color-gray;
      background: $color-white;
      letter-spacing: 0;
      top: 0;
      margin-#{$ldirection}: -13px;
    }
  }
  @include breakpoint($medium-up) {
    &::before {
      content: '';
    }
  }
  &__image {
    &-container {
      @include breakpoint($small-down) {
        margin-top: 13px;
        border-top: 1px solid $color-gray;
      }
      @include breakpoint($medium-up) {
        float: $ldirection;
        width: 49%;
      }
      text-align: center;
      .product-palette__photo {
        display: block;
        @include breakpoint($medium-up) {
          margin: 0 0 20px 0;
        }
      }
    }
  }
  &__details {
    &-container {
      margin-top: 20px;
      @include breakpoint($medium-up) {
        border: 0;
        margin: 0;
        float: $rdirection;
        width: 49%;
        display: flex;
        flex-direction: column;
        min-height: 44vh;
      }
    }
    &-wrapper {
      flex: 1;
      overflow: auto;
      padding-top: 10px;
      border-top: 1px solid $color-gray;
      margin: 0 40px 0 40px;
      @include breakpoint($medium-up) {
        margin: 0;
        border: 0;
        padding: 0;
      }
    }
    &-title {
      @include breakpoint($small-down) {
        display: none;
      }
    }
    &-group {
      &.first {
        @include breakpoint($medium-up) {
          border: none;
          padding: 0;
        }
      }
      &-title {
        margin: 0 0 10px 0;
      }
      &-shades {
        display: inline-block;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      &-shade {
        @include swap_direction(margin, 0 10px 10px 0);
        float: $ldirection;
      }
    }
  }
  &__link-launch {
    text-transform: uppercase;
    @include breakpoint($small-down) {
      @include h4;
      font-size: 14px;
      border-bottom: 2px solid $color-red;
      margin: 20px 0 10px 0;
      display: inline-block;
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
    @include breakpoint($medium-up) {
      border: 2px solid $color-black;
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &-desktop {
      @include breakpoint($small-down) {
        display: none;
      }
    }
    &-mobile {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  &__actions {
    text-align: $rdirection;
    @include breakpoint($small-down) {
      padding: 20px 0 30px 0;
      text-align: center;
    }
    .product__info--palette {
      @include breakpoint($small-down) {
        display: none;
      }
      margin: 0 0 20px 0;
    }
  }
}
